var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.render)?_c('b-table-simple',{staticClass:"competences-table page-break",attrs:{"small":"","responsive":"","border":""}},[_c('b-thead',[_c('b-tr',[(_vm.VisibleOrder)?_c('b-th',[_vm._v(_vm._s(_vm.order))]):_vm._e()],1)],1),_c('b-tbody',[_c('b-tr',[_c('td',{attrs:{"colspan":"5"}},[_c('b-card-group',{staticClass:"mb-3 b-card-container"},[_c('b-card',{staticClass:"custom-card text-center",attrs:{"border-variant":"light","header":"TOTAL"}},[_c('div',{staticClass:"inner-card-body total"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  'Máximo sugerido en ' +
                  _vm.$getVisibleNames(
                    'manual.total_pedagogical_hours',
                    true,
                    'Total de Horas Pedagógicas'
                  )
                ),expression:"\n                  'Máximo sugerido en ' +\n                  $getVisibleNames(\n                    'manual.total_pedagogical_hours',\n                    true,\n                    'Total de Horas Pedagógicas'\n                  )\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"hours-section"},[_c('div',{staticClass:"hours-header"},[_vm._v("Horas")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getTotal(1, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getTotal(1, false),"sub_type":3,"sub_type_id":1,"limit_type":1},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2),_c('div',{staticClass:"credits-section"},[_c('div',{staticClass:"credits-header"},[_vm._v("Créditos")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getTotal(2, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getTotal(2, false),"sub_type":3,"sub_type_id":1,"limit_type":2},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2)])]),_c('b-card',{staticClass:"custom-card text-center",attrs:{"border-variant":"light","header":_vm.$getVisibleNames(
                'mesh.formationarea',
                false,
                'Área De Formación'
              ).toUpperCase()}},[_c('div',{staticClass:"inner-card"},[_c('div',{staticClass:"inner-card-body"},[_c('div',{staticClass:"formation-area-name"},[_vm._v(" Sin "+_vm._s(_vm.$getVisibleNames( "mesh.formationarea", false, "Área De Formación" ))+" ")])]),_c('div',{staticClass:"inner-card-body"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    'Máximo sugerido en ' +
                    _vm.$getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )
                  ),expression:"\n                    'Máximo sugerido en ' +\n                    $getVisibleNames(\n                      'manual.total_pedagogical_hours',\n                      true,\n                      'Total de Horas Pedagógicas'\n                    )\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"hours-section"},[_c('div',{staticClass:"hours-header"},[_vm._v("Horas")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getLimitFormationArea(null, 1, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getLimitFormationArea(null, 1, false),"sub_type":2,"sub_type_id":null,"limit_type":1},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2),_c('div',{staticClass:"credits-section"},[_c('div',{staticClass:"credits-header"},[_vm._v("Créditos")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getLimitFormationArea(null, 2, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getLimitFormationArea(null, 2, false),"sub_type":2,"sub_type_id":null,"limit_type":2},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2)])]),_vm._l((_vm.formationAreas),function(formation_area){return _c('div',{key:formation_area.id,staticClass:"inner-card"},[_c('div',{staticClass:"inner-card-body"},[_c('div',{staticClass:"formation-area-name"},[_vm._v(" "+_vm._s(formation_area.name)+" ")])]),_c('div',{staticClass:"inner-card-body"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    'Máximo sugerido en ' +
                    _vm.$getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )
                  ),expression:"\n                    'Máximo sugerido en ' +\n                    $getVisibleNames(\n                      'manual.total_pedagogical_hours',\n                      true,\n                      'Total de Horas Pedagógicas'\n                    )\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"hours-section"},[_c('div',{staticClass:"hours-header"},[_vm._v("Horas")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getLimitFormationArea(formation_area.id, 1, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getLimitFormationArea(formation_area.id, 1, false),"sub_type":2,"sub_type_id":formation_area.id,"limit_type":1},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2),_c('div',{staticClass:"credits-section"},[_c('div',{staticClass:"credits-header"},[_vm._v("Créditos")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getLimitFormationArea(formation_area.id, 2, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getLimitFormationArea(formation_area.id, 2, false),"sub_type":2,"sub_type_id":formation_area.id,"limit_type":2},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2)])])})],2),(_vm.egress_profile && _vm.egress_profile.max_semesters > 0)?_c('b-card',{staticClass:"custom-card text-center",attrs:{"border-variant":"light","header":"NIVEL"}},_vm._l((_vm.egress_profile.max_semesters),function(level){return _c('div',{key:level,staticClass:"inner-card"},[_c('div',{staticClass:"inner-card-body"},[_c('div',{staticClass:"level-name"},[_vm._v(_vm._s(_vm.numberToWord(level))+" NIVEL")])]),_c('div',{staticClass:"inner-card-body"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    'Máximo sugerido en ' +
                    _vm.$getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )
                  ),expression:"\n                    'Máximo sugerido en ' +\n                    $getVisibleNames(\n                      'manual.total_pedagogical_hours',\n                      true,\n                      'Total de Horas Pedagógicas'\n                    )\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"hours-section"},[_c('div',{staticClass:"hours-header"},[_vm._v("Horas")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getLimitLevel(level, 1, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getLimitLevel(level, 1, false),"sub_type":1,"sub_type_id":level,"limit_type":1},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    'Máximo sugerido de Créditos'
                  ),expression:"\n                    'Máximo sugerido de Créditos'\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"credits-section"},[_c('div',{staticClass:"credits-header"},[_vm._v("Créditos")]),(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getLimitLevel(level, 2, true))+" ")]):[_c('LimitStudyPlanInputComponent',{attrs:{"modality_egress_profile_id":_vm.modality_egress_profile_id,"LimitStudyPlan":_vm.getLimitLevel(level, 2, false),"sub_type":1,"sub_type_id":level,"limit_type":2},on:{"created":_vm.createLimitStudyPlan,"updated":_vm.updateLimitStudyPlan}})]],2)])])}),0):_vm._e()],1)],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }