<template>
  <b-table-simple
    v-if="render"
    small
    responsive
    border
    class="competences-table page-break"
  >
    <b-thead
      ><b-tr>
        <b-th v-if="VisibleOrder">{{ order }}</b-th>
        <!-- <b-th colspan="4" class="text-center font-weight-bold">
          REQUISITOS DE HORAS Y CRÉDITOS
        </b-th> -->
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <td colspan="5">
          <b-card-group class="mb-3 b-card-container">
            <b-card
              border-variant="light"
              header="TOTAL"
              class="custom-card text-center"
            >
              <div class="inner-card-body total">
                <div
                  class="hours-section"
                  v-b-tooltip.v-secondary.noninteractive="
                    'Máximo sugerido en ' +
                    $getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )
                  "
                >
                  <div class="hours-header">Horas</div>
                  <span v-if="!allows_crud">
                    {{ getTotal(1, true) }}
                  </span>
                  <template v-else>
                    <LimitStudyPlanInputComponent
                      :modality_egress_profile_id="modality_egress_profile_id"
                      :LimitStudyPlan="getTotal(1, false)"
                      :sub_type="3"
                      :sub_type_id="1"
                      :limit_type="1"
                      @created="createLimitStudyPlan"
                      @updated="updateLimitStudyPlan"
                    ></LimitStudyPlanInputComponent>
                  </template>
                </div>
                <div class="credits-section">
                  <div class="credits-header">Créditos</div>
                  <span v-if="!allows_crud">
                    {{ getTotal(2, true) }}
                  </span>
                  <template v-else>
                    <LimitStudyPlanInputComponent
                      :modality_egress_profile_id="modality_egress_profile_id"
                      :LimitStudyPlan="getTotal(2, false)"
                      :sub_type="3"
                      :sub_type_id="1"
                      :limit_type="2"
                      @created="createLimitStudyPlan"
                      @updated="updateLimitStudyPlan"
                    ></LimitStudyPlanInputComponent>
                  </template>
                </div>
              </div>
            </b-card>
            <b-card
              border-variant="light"
              :header="
                $getVisibleNames(
                  'mesh.formationarea',
                  false,
                  'Área De Formación'
                ).toUpperCase()
              "
              class="custom-card text-center"
            >
              <div class="inner-card">
                <div class="inner-card-body">
                  <div class="formation-area-name">
                    Sin
                    {{
                      $getVisibleNames(
                        "mesh.formationarea",
                        false,
                        "Área De Formación"
                      )
                    }}
                  </div>
                </div>
                <div class="inner-card-body">
                  <div
                    class="hours-section"
                    v-b-tooltip.v-secondary.noninteractive="
                      'Máximo sugerido en ' +
                      $getVisibleNames(
                        'manual.total_pedagogical_hours',
                        true,
                        'Total de Horas Pedagógicas'
                      )
                    "
                  >
                    <div class="hours-header">Horas</div>
                    <span v-if="!allows_crud">
                      {{ getLimitFormationArea(null, 1, true) }}
                    </span>
                    <template v-else>
                      <LimitStudyPlanInputComponent
                        :modality_egress_profile_id="modality_egress_profile_id"
                        :LimitStudyPlan="getLimitFormationArea(null, 1, false)"
                        :sub_type="2"
                        :sub_type_id="null"
                        @created="createLimitStudyPlan"
                        @updated="updateLimitStudyPlan"
                        :limit_type="1"
                      ></LimitStudyPlanInputComponent>
                    </template>
                  </div>
                  <div class="credits-section">
                    <div class="credits-header">Créditos</div>
                    <span v-if="!allows_crud">
                      {{ getLimitFormationArea(null, 2, true) }}
                    </span>
                    <template v-else>
                      <LimitStudyPlanInputComponent
                        :modality_egress_profile_id="modality_egress_profile_id"
                        :LimitStudyPlan="getLimitFormationArea(null, 2, false)"
                        :sub_type="2"
                        @created="createLimitStudyPlan"
                        @updated="updateLimitStudyPlan"
                        :sub_type_id="null"
                        :limit_type="2"
                      ></LimitStudyPlanInputComponent>
                    </template>
                  </div>
                </div>
              </div>
              <div
                v-for="formation_area in formationAreas"
                :key="formation_area.id"
                class="inner-card"
              >
                <div class="inner-card-body">
                  <div class="formation-area-name">
                    {{ formation_area.name }}
                  </div>
                </div>
                <div class="inner-card-body">
                  <div
                    class="hours-section"
                    v-b-tooltip.v-secondary.noninteractive="
                      'Máximo sugerido en ' +
                      $getVisibleNames(
                        'manual.total_pedagogical_hours',
                        true,
                        'Total de Horas Pedagógicas'
                      )
                    "
                  >
                    <div class="hours-header">Horas</div>
                    <span v-if="!allows_crud">
                      {{ getLimitFormationArea(formation_area.id, 1, true) }}
                    </span>
                    <template v-else>
                      <LimitStudyPlanInputComponent
                        :modality_egress_profile_id="modality_egress_profile_id"
                        :LimitStudyPlan="
                          getLimitFormationArea(formation_area.id, 1, false)
                        "
                        :sub_type="2"
                        @created="createLimitStudyPlan"
                        @updated="updateLimitStudyPlan"
                        :sub_type_id="formation_area.id"
                        :limit_type="1"
                      ></LimitStudyPlanInputComponent>
                    </template>
                  </div>
                  <div class="credits-section">
                    <div class="credits-header">Créditos</div>
                    <span v-if="!allows_crud">
                      {{ getLimitFormationArea(formation_area.id, 2, true) }}
                    </span>
                    <template v-else>
                      <LimitStudyPlanInputComponent
                        :modality_egress_profile_id="modality_egress_profile_id"
                        :LimitStudyPlan="
                          getLimitFormationArea(formation_area.id, 2, false)
                        "
                        :sub_type="2"
                        @created="createLimitStudyPlan"
                        @updated="updateLimitStudyPlan"
                        :sub_type_id="formation_area.id"
                        :limit_type="2"
                      ></LimitStudyPlanInputComponent>
                    </template>
                  </div>
                </div>
              </div>
            </b-card>
            <b-card
              border-variant="light"
              header="NIVEL"
              class="custom-card text-center"
              v-if="egress_profile && egress_profile.max_semesters > 0"
            >
              <div
                v-for="level in egress_profile.max_semesters"
                :key="level"
                class="inner-card"
              >
                <div class="inner-card-body">
                  <div class="level-name">{{ numberToWord(level) }} NIVEL</div>
                </div>
                <div class="inner-card-body">
                  <div
                    class="hours-section"
                    v-b-tooltip.v-secondary.noninteractive="
                      'Máximo sugerido en ' +
                      $getVisibleNames(
                        'manual.total_pedagogical_hours',
                        true,
                        'Total de Horas Pedagógicas'
                      )
                    "
                  >
                    <div class="hours-header">Horas</div>
                    <span v-if="!allows_crud">
                      {{ getLimitLevel(level, 1, true) }}
                    </span>
                    <template v-else>
                      <LimitStudyPlanInputComponent
                        :modality_egress_profile_id="modality_egress_profile_id"
                        :LimitStudyPlan="getLimitLevel(level, 1, false)"
                        :sub_type="1"
                        :sub_type_id="level"
                        :limit_type="1"
                        @created="createLimitStudyPlan"
                        @updated="updateLimitStudyPlan"
                      ></LimitStudyPlanInputComponent>
                    </template>
                  </div>
                  <div
                    class="credits-section"
                    v-b-tooltip.v-secondary.noninteractive="
                      'Máximo sugerido de Créditos'
                    "
                  >
                    <div class="credits-header">Créditos</div>
                    <span v-if="!allows_crud">
                      {{ getLimitLevel(level, 2, true) }}
                    </span>
                    <template v-else>
                      <LimitStudyPlanInputComponent
                        :modality_egress_profile_id="modality_egress_profile_id"
                        :LimitStudyPlan="getLimitLevel(level, 2, false)"
                        :sub_type="1"
                        :sub_type_id="level"
                        :limit_type="2"
                        @created="createLimitStudyPlan"
                        @updated="updateLimitStudyPlan"
                      ></LimitStudyPlanInputComponent>
                    </template>
                  </div>
                </div>
              </div>
            </b-card>
          </b-card-group></td
      ></b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "LimitStudyPlan",
  components: {
    LimitStudyPlanInputComponent: () =>
      import("./LimitStudyPlanInputComponent.vue"),
  },
  props: {
    modality_egress_profile_id: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
    },
    VisibleOrder: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    // limit_study_plan: {
    //   type: Array,
    //   default: null,
    // },
  },
  data() {
    return {
      render: false,
      limit_study_plan: null,
      egress_profile: null,
    };
  },
  computed: {
    ...mapGetters({
      numbersToWord: names.NUMBERS_TO_WORDS,
      formationAreas: names.FORMATION_AREAS,
    }),
    limitLevel() {
      let list = [];
      if (this.limit_study_plan == null) return [];
      this.limit_study_plan.forEach((element) => {
        if (element.sub_type == 1) list.push(element);
      });
      return list;
    },
    limitFormationArea() {
      let list = [];
      if (this.limit_study_plan == null) return [];
      this.limit_study_plan.forEach((element) => {
        if (element.sub_type == 2) list.push(element);
      });
      return list;
    },
    limitTotal() {
      let list = [];
      if (this.limit_study_plan == null) return [];
      this.limit_study_plan.forEach((element) => {
        if (element.sub_type == 3) list.push(element);
      });
      return list;
    },
  },
  methods: {
    numberToWord(number) {
      return this.numbersToWord[number - 1]
        ? this.numbersToWord[number - 1]
        : number;
    },
    getLimitLevel(level_id, limit_type, value = false) {
      const level = this.limitLevel.find(
        (x) => x.sub_type_id == parseInt(level_id) && x.limit_type == limit_type
      );
      if (value) return level ? level.restriction_value : 0;
      else return level;
    },
    getLimitFormationArea(foramtion_area_id, limit_type, value = false) {
      const foramtion_area = this.limitFormationArea.find(
        (x) => x.sub_type_id == foramtion_area_id && x.limit_type == limit_type
      );
      if (value) return foramtion_area ? foramtion_area.restriction_value : 0;
      else return foramtion_area;
    },
    getTotal(limit_type, value = false) {
      const total = this.limitTotal.find((x) => x.limit_type == limit_type);
      if (value) return total ? total.restriction_value : 0;
      else return total;
    },
    createLimitStudyPlan(limit_study_plan) {
      this.limit_study_plan.push(limit_study_plan);
    },
    updateLimitStudyPlan(limit_study_plan) {
      const index = this.limit_study_plan.findIndex(
        (x) => x.id == limit_study_plan.id
      );
      if (index != -1) {
        this.limit_study_plan.splice(index, 1, limit_study_plan);
      }
    },
  },
  mounted() {},
  created() {
    this.$restful
      .Get(
        `/mesh/modality_egress_profile_levels/?modality_egress_profile=${this.modality_egress_profile_id}`
      )
      .then((response) => {
        this.egress_profile = response;
        this.$store.dispatch("CHECK_NUMBERS_TO_WORDS", response.max_semesters);
      });

    this.$restful
      .Get(
        `/mesh/limit-study-plan/?modality_egress_profile=${this.modality_egress_profile_id}`
      )
      .then((response) => {
        this.limit_study_plan = response;
        this.render = true;
      });
  },
};
</script>

<style scoped>
table thead >>> th,
.competences-table >>> .custom-footer {
  background-color: var(--secondary-color) !important;
}
.page-break {
  page-break-before: auto !important;
  page-break-after: auto !important;
  page-break-inside: avoid !important;
}
/* .card-deck >>>  */
.b-card-container {
  display: block !important;
  /* display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-evenly !important; */
}
.card-header {
  background-color: var(--primary-color) !important;
  font-weight: bold;
}
.custom-card {
  width: 100%;
  /* min-width: 20em !important; */
}
.card-body {
  padding: 0.2em 0.4em;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}
.inner-card {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  flex-grow: 1;
  justify-content: space-evenly !important;
  width: 18rem;
  background: var(--primary-hover-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  margin: 0.3em 0.2em;
}
.inner-card-body {
  display: flex;
  width: 50%;
  text-align: center;
}
.inner-card-body.total {
  width: 100% !important;
}
.level-name,
.formation-area-name {
  text-align: center;
  width: 100%;
  padding: 0.8em 0;
}
.hours-section,
.credits-section {
  display: block;
  width: 100%;
  background-color: #e9f1f8;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  margin: 0.1em;
}
.hours-header,
.credits-header {
  font-size: var(--thirth-font-size);
  width: 100%;
  background-color: var(--primary-color);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  font-weight: bold;
}
/* Media Query para ajustar el ancho de la tarjeta a la pantalla del dispositivo */
/* @media (max-width: 580px) {
  .custom-card {
    width: 100%;
  }
} */
@media (max-width: 780px) {
  .inner-card {
    width: 100% !important;
  }
}
</style>